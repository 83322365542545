import { useVendorDataContext } from '_contexts/VendorDataContext';
import { IChildVendor } from '_models/vendor.interface';
import React, { useState } from 'react'

interface ISubVendorSelectorProps {
    onSelectionChange: (selectedChildVendor: IChildVendor | null) => void;
}

const SubVendorSelector = ({ onSelectionChange }: ISubVendorSelectorProps) => {
    const { childrenVendors, vendorProfile } = useVendorDataContext();
    const [selectedChildVendor, setSelectedChildVendor] = useState<IChildVendor | null>(null);

    const handleUpdateSelectedChildVendor = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const emailId = e.target.value;
        const selectedVendor = childrenVendors.find((vendor) => vendor.email === emailId);
        setSelectedChildVendor(selectedVendor ?? null);
        onSelectionChange(selectedVendor ?? null);
    }

    return (
        <select
            name='child-vendor'
            id='child-vendor'
            value={selectedChildVendor ? selectedChildVendor.email : ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleUpdateSelectedChildVendor(e)}
        >
            <option value='' key='default'>
                {/* Select domain */}
                {vendorProfile?.org_name ?? 'Select domain'}
            </option>
            {childrenVendors.map((vendor, index) => <option key={index} value={vendor.email}>
                {vendor.org_name}
            </option>)}
        </select>
    )
}

export default SubVendorSelector