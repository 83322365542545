import React, { FormEvent, useEffect, useState } from 'react';
import '_modules/VendorModule/_styles/Vendor.scss';
import { ITripRequest, ITripPassenger, IProductFeatureSelection, ISegmentLimit, ITripProductFeatureSubscription, SegmentActionType, ITripFlightSegment, ITripResponse, ICommunicationPreferences, ITripMandatoryFields, ITripDisplayFields, IChildVendor, IVendorProductFeatureData } from '_models/vendor.interface';
import { VendorConstants } from '_constants/Vendor.constants';
import { UtilSvc } from '_services/util.service';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
// import { useToast } from '_contexts/ToastContext';
import ProductFeatureSelector from '_modules/VendorModule/_components/ProductFeatureSelector/ProductFeatureSelector';
import PassengerData from '_modules/VendorModule/_components/PassengerData/PassengerData';
import FlightSegmentData from '_modules/VendorModule/_components/FlightSegmentData/FlightSegmentData';
import TripCreationResponse from '_modules/VendorModule/_components/TripCreationResponse/TripCreationResponse';
import { useVendorDataContext } from '_contexts/VendorDataContext';
// import { Dropdown, } from "react-bootstrap";
// import { FaChevronDown } from "react-icons/fa";
import { IChannelData } from "_models/vendor.interface";
import { deepCopy } from '_services/utils.service';
import { Popover, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { toast, ToastPosition } from 'react-toastify';
import SubVendorSelector from '../SubVendorSelector/SubVendorSelector';
import TripCreationResponse2 from '../TripCreationResponse/TripCreationResponse2';

interface ITripCreateFormProps {
    fillupData?: ITripRequest;
    displayError?: boolean;
    onResetTrip: () => void;
    // showChannelTypeForTripB?: boolean;
    isEmbedded: boolean;
}

// const toastPosition: ToastPosition | undefined = 'bottom-left';

const TripCreateForm: React.FC<ITripCreateFormProps> = (props: ITripCreateFormProps) => {

    const { vendorProfile, childrenVendors } = useVendorDataContext();
    // const toast = useToast();
    const vendorMgmntSvc = VendorMgmntSvc.getInstance();
    const utilSvc = UtilSvc.getInstance();

    const [trip, setTrip] = useState<ITripRequest>();
    const [tripResponse, setTripResponse] = useState<ITripResponse | null>(null);
    // const [keyData, setKeyData] = useState<number>(Date.now());
    const [toastPosition, setToastPosition] = useState<ToastPosition | undefined>(undefined);

    const flightSegmentLimit: ISegmentLimit = { max: 6, min: 1 };
    const passengerSegmentLimit: ISegmentLimit = { max: 9, min: 1 };

    const [selectedChildVendor, setSelectedChildVendor] = useState<IChildVendor | null>(null);

    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const [resetFlag, setResetFlag] = useState<boolean>(false);

    const [selectedPdtFeatures, setSelectedPdtFeatures] = useState<ITripProductFeatureSubscription[]>([]);
    const [passengerCount, setPassengerCount] = useState<string>('');
    const [productIds, setProductIds] = useState<string[]>([]);
    const [channels, setChannels] = useState<IChannelData[]>(deepCopy(VendorConstants.CHANNEL_DATA))

    const [mandatoryFields, setMandatoryFields] = useState<ITripMandatoryFields>(deepCopy(VendorConstants.MANDATORY_FIELDS));
    const [displayFields, setDisplayFields] = useState<ITripDisplayFields>(deepCopy(VendorConstants.DISPLAY_FILEDS));


    const [infoPopoverAnchorEl, setInfoPopoverAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleInfoPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setInfoPopoverAnchorEl(event.currentTarget);
    };
    const handleInfoPopoverClose = () => {
        setInfoPopoverAnchorEl(null);
    };

    // useEffect(() => {
    //     getChildrenVendor();
    // }, []);
    useEffect(() => {
        if (props.fillupData) fillupTripData(props.fillupData);
        else setupInitialTrip();
    }, [props.fillupData])

    useEffect(() => {
        if (vendorProfile && trip && !trip.itinerary.langCode) {
            setupLanguageCode();
            filterSubcribedChannels(channels, vendorProfile.communicationPreferences)
            // resetChannelStatus();
        }

        if (vendorProfile && props.isEmbedded) {
            setupIframeOptions();
            // getChildrenVendor();
        }
        if (vendorProfile && !vendorProfile?.uifeatureVisibility?.productFeatures) {
            const selProductFeature = handleDefaultSelectedProductFeature(vendorProfile?.productFeatures);
            setSelectedPdtFeatures(selProductFeature);
        }

    }, [vendorProfile, props.isEmbedded])

    useEffect(() => {
        setupDisplayAndMandatoryFields();
    }, [selectedPdtFeatures])

    // useEffect(() => {
    //     resetChannelStatus();
    // }, [props.showChannelTypeForTripB])

    const setupLanguageCode = () => {
        setTrip((prevTrip) => {
            if (!prevTrip) return prevTrip;
            return {
                ...prevTrip,
                itinerary: {
                    ...prevTrip.itinerary,
                    langCode: vendorProfile?.defaultLangCode ? vendorProfile.defaultLangCode : VendorConstants.TRIP_LANGUAGE[0].code
                },
            }
        });
    }

    const handleDefaultSelectedProductFeature = (data: IVendorProductFeatureData[]): ITripProductFeatureSubscription[] => {
        const productMap: Record<string, string[]> = {};
        data.forEach(({ prodCode, featureCode }) => {
            if (!productMap[prodCode]) {
                productMap[prodCode] = [];
            }
            productMap[prodCode].push(featureCode);
        });
        return Object.entries(productMap).map(([product, features]) => ({ product, features }));
    }

    const setupIframeOptions = () => {
        setDisplayFields((prev: ITripDisplayFields) => ({ ...prev, isEmbedded: true }));
        setToastPosition('bottom-left');



        // const pdtIds: string[] = vendorProfile?.productFeatures.map((product) => product.id).includes('FW_FDA') ? ['FW_FDA'] : [];
        // setProductIds(pdtIds);

        // const productFeatures = [{
        //     product: 'FW',
        //     features: ['FDA']
        // }];
        // setSelectedPdtFeatures(productFeatures);
    }

    const resetData = () => {
        // setKeyData(Date.now());
        setTripResponse(null);

        setDirtyFormFlag(false);
        setResetFlag(true);
        setTimeout(() => {
            setResetFlag(false)
        }, 500);

        // setSelectedPdtFeatures([]);
        // setupDisplayAndMandatoryFields([]);
        // setProductIds([]);
        if (props.isEmbedded) {
            setupIframeOptions();
        }

        setPassengerCount('');
        setSelectedChildVendor(null);

        const data: ITripRequest = deepCopy(VendorConstants.DEFAULT_TRIP_REQUEST);
        data.itinerary.passengers[0] = setPassengerId(data.itinerary.passengers[0]);
        data.itinerary.segments[0] = setFlightId(data.itinerary.segments[0]);
        data.itinerary.pricing = deepCopy(VendorConstants.DEFAULT_TRIP_PRICING);
        data.itinerary.commChannelPrefs = [];
        setTrip(data);
        setupLanguageCode();

        vendorProfile && filterSubcribedChannels(channels, vendorProfile.communicationPreferences)
        props?.onResetTrip();
    }

    const createTrip = () => {
        setDirtyFormFlag(true);

        if (!checkValidity()) {
            toast.error(`Please fill/select all mandatory fields correctly.`, { position: toastPosition });
            return;
        }

        const requestPayload: ITripRequest | null = createRequestPayload();
        if (requestPayload) {
            // toast.success('Trip created.', { position: toastPosition });
            // console.log(requestPayload);

            // **** //

            vendorMgmntSvc.createTripV2(requestPayload)
                .then((res: any) => {
                    if (res?.data) {
                        const resData: ITripResponse = res.data;
                        setTripResponse(resData);
                        const invalidCount: number = resData.invalid_segments.reduce((total, cur) => total + cur.invalidSegments.length, 0);
                        const totalCount = requestPayload.itinerary.segments.length;
                        const validCount = totalCount - invalidCount;
                        if (invalidCount) toast.error(`Trip creation unsuccessful for ${invalidCount} segment${invalidCount > 1 ? 's' : ''}`, { position: toastPosition });
                        if (validCount) toast.success(`Trip creation successful for ${validCount} segment${validCount > 1 ? 's' : ''}`, { position: toastPosition });
                        if (!invalidCount && !validCount) toast.warning(`Trip creation api called`, { position: toastPosition });
                    } else {
                        console.warn(res);
                        toast.warning('Failed to create trip. Response status is false.', { position: toastPosition });
                    }
                });
        }
    }
    const createRequestPayload = (): ITripRequest | null => {
        const selPdtFeatures = fetchSelectedPdtFeatures();
        if (trip && selPdtFeatures) {
            const requestPayload: ITripRequest = deepCopy(trip);
            if (selectedChildVendor) {
                requestPayload.childVendorId = selectedChildVendor?.email;
            }
            requestPayload.itinerary.passengers.forEach((passenger: ITripPassenger) => {
                delete passenger.id;
                if (!passenger.phoneNumber) passenger.phoneCode = '';
            });
            const primaryPassenger: ITripPassenger | undefined = requestPayload.itinerary.passengers.find((passenger: ITripPassenger) => passenger.primary);
            if (primaryPassenger) requestPayload.itinerary.passengers.forEach((passenger: ITripPassenger) => {
                if (!passenger.primary) {
                    if (!passenger.email || !utilSvc.checkEmailVaildity(passenger.email)) {
                        passenger.email = primaryPassenger.email;
                    }
                    if (!passenger.phoneNumber || !utilSvc.checkTripPhoneVaildity(passenger.phoneCode, passenger.phoneNumber)) {
                        passenger.phoneCode = primaryPassenger.phoneCode;
                        passenger.phoneNumber = primaryPassenger.phoneNumber;
                    };
                }
            });
            if (displayFields.FW_display) {
                const contactPassenger: ITripPassenger = requestPayload.itinerary.passengers[0];
                delete contactPassenger.gender;
                contactPassenger.ktn = '';
                contactPassenger.dob = '';
                contactPassenger.nationality = '';
                contactPassenger.passportNo = '';
                contactPassenger.passportIssueDate = '';
                contactPassenger.passportExpDate = '';
                for (let i = 1; i < Number(passengerCount); i++) {
                    const temp: ITripPassenger = deepCopy(contactPassenger);
                    temp.primary = false;
                    temp.phoneCode = '';
                    temp.phoneNumber = '';
                    temp.email = '';
                    requestPayload.itinerary.passengers.push(temp);
                }
            }
            requestPayload.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                if (displayFields.FW_display) {
                    flight.pnr = '';
                    flight.passengerExtra = [];
                }
                delete flight.id;
            });
            requestPayload.itinerary.subscriptions = selPdtFeatures;
            requestPayload.itinerary.pricing = { ...requestPayload.itinerary.pricing, passengerCount: requestPayload.itinerary.passengers.length, price: Number(requestPayload.itinerary.pricing.price) };
            requestPayload.itinerary.commChannelPrefs = mandatoryFields.channelType ? getSelectedChanelValues() : [];
            return requestPayload;
        }
        return null;
    }
    const checkValidity = (): boolean => {
        if (!trip) return false;
        if (!fetchSelectedPdtFeatures()) return false;
        if (!trip.itinerary.bookingno) return false;
        if (displayFields.FW_display) {
            if (isNaN(Number(passengerCount)) || Number(passengerCount) < passengerSegmentLimit.min || Number(passengerCount) > passengerSegmentLimit.max) return false;
        }
        if (!trip.itinerary.passengers.some((passenger: ITripPassenger) => passenger.primary === true)) {
            toast.error('Atleast one passenger must be selected as primary', { position: toastPosition });
            return false;
        }
        for (const passenger of trip.itinerary.passengers) {
            if (!passenger.firstname || !passenger.lastname) return false;
            if (passenger.primary) {
                if (!utilSvc.checkEmailVaildity(passenger.email)) return false;
                if ((mandatoryFields.phone || passenger.phoneNumber) && !utilSvc.checkTripPhoneVaildity(passenger.phoneCode, passenger.phoneNumber)) return false;
            }
            if (!displayFields.FW_display) {
                if (!passenger.gender) return false;
            }
        }
        for (const flight of trip.itinerary.segments) {
            if (!flight.airline || !flight.flightno || !flight.from || !flight.to || !flight.deptdate || !flight.depttime) return false;
            if (!displayFields.FW_display) {
                if (!flight.pnr) return false;
            }
        }
        if (mandatoryFields.channelType) {
            let selectedChannels = getSelectedChanelValues();
            if (!selectedChannels?.length)
                return false;
        }
        return true;
    }

    const setPassengerId = (passenger: ITripPassenger): ITripPassenger => ({ ...passenger, id: `passenger-${utilSvc.generateUniqueId()}` });
    const setFlightId = (flight: ITripFlightSegment): ITripFlightSegment => ({ ...flight, id: `flight-${utilSvc.generateUniqueId()}` });

    const setupInitialTrip = () => {
        const data: ITripRequest = deepCopy(VendorConstants.DEFAULT_TRIP_REQUEST);
        data.itinerary.passengers[0] = setPassengerId(data.itinerary.passengers[0]);
        data.itinerary.segments[0] = setFlightId(data.itinerary.segments[0]);
        setTrip(data);
    }
    const fillupTripData = async (fillData: ITripRequest) => {
        if (!fillData.itinerary.bookingno) {
            fillData.itinerary.bookingno = "";
        }
        if (!fillData.itinerary.langCode) {
            fillData.itinerary.langCode = vendorProfile?.defaultLangCode ? vendorProfile.defaultLangCode : VendorConstants.TRIP_LANGUAGE[0].code;
        }
        if (!fillData.itinerary.passengers || fillData.itinerary.passengers.length === 0) {
            fillData.itinerary.passengers = [deepCopy(VendorConstants.DEFAULT_TRIP_PASSENGER)];
        }
        if (!fillData.itinerary.segments || fillData.itinerary.segments.length === 0) {
            fillData.itinerary.segments = [deepCopy(VendorConstants.DEFAULT_TRIP_FLIGHT_SEGMENT)];
        } else {
            fillData.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                if (!flight.passengerExtra) {
                    let tempArr = [];
                    for (let i = 0; i < fillData.itinerary.passengers.length; i++) {
                        tempArr.push(deepCopy(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA));
                    }
                    flight.passengerExtra = tempArr;
                }
            });
        }
        if (!fillData.itinerary.subscriptions) {
            fillData.itinerary.subscriptions = [];
        }
        if (!fillData.itinerary.pricing) {
            fillData.itinerary.pricing = deepCopy(VendorConstants.DEFAULT_TRIP_PRICING);
        }
        fillData.itinerary.segments.forEach((flight: ITripFlightSegment) => {
            if (flight?.depttime && flight.depttime.length > 5) {
                flight.depttime = flight.depttime.slice(0, 5);
            }
        });

        const productIdsArray: string[] = [];
        fillData.itinerary.subscriptions.forEach((sub: ITripProductFeatureSubscription) => {
            sub.features.forEach((f) => {
                productIdsArray.push(`${sub.product}_${f}`)
            });
        })
        setProductIds(productIdsArray);

        for (let i = 0; i < fillData.itinerary.passengers.length; i++) {
            fillData.itinerary.passengers[i] = setPassengerId(fillData.itinerary.passengers[i]);
            fillData.itinerary.passengers[i].primary = false;
            if (!fillData.itinerary.passengers[i].address) {
                fillData.itinerary.passengers[i].address = deepCopy(VendorConstants.DEFAULT_ADDRESS);
            }
        }
        fillData.itinerary.passengers[0].primary = true;

        for (let i = 0; i < fillData.itinerary.segments.length; i++) {
            fillData.itinerary.segments[i] = setFlightId(fillData.itinerary.segments[i]);
        }

        setTrip(fillData);

        if (props.displayError) setDirtyFormFlag(true);
    }

    const handleUpdateBookingDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        data.itinerary.bookingno = e.target.value;
        setTrip(data);
    }
    const handleUpdatePricingDetails = (e: React.ChangeEvent<HTMLInputElement>, type: 'currency-code' | 'price') => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const value = e.target.value;
        switch (type) {
            case 'currency-code':
                data.itinerary.pricing.currencyCode = value;
                break;
            case 'price':
                data.itinerary.pricing.price = value;
                break;
        }
        setTrip(data);
    }
    const handlePassengerCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassengerCount(e.target.value);
    }
    const handleUpdateTripLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        data.itinerary.langCode = e.target.value;
        setTrip(data);
        // setTrip(prev => {
        //     if (!prev) return prev;
        //     return {...prev}
        // });
    }

    const handlePassengerSegment = (type: SegmentActionType, passengerInfo: { index: number, count: number }) => {
        const { index, count } = passengerInfo;
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const passengers: ITripPassenger[] = data.itinerary.passengers;
        switch (type) {
            case 'add':
                if (passengers?.length >= passengerSegmentLimit.max) {
                    toast.error(`The maximum passenger limit of ${passengerSegmentLimit.max} has been reached.`, { position: toastPosition });
                    return;
                }
                let newPassengers = [];
                let newPassengersExtra: any[] = [];
                for (let i = 0; i < count; i++) {
                    const passenger: ITripPassenger = setPassengerId(deepCopy(VendorConstants.DEFAULT_TRIP_PASSENGER));
                    newPassengers.push(passenger);
                    newPassengersExtra.push(deepCopy(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA))
                }
                toast.success(`Passenger has been added.`, { position: toastPosition });
                passengers.splice(index + 1, 0, ...newPassengers);
                data.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                    flight?.passengerExtra?.splice(index + 1, 0, ...newPassengersExtra);
                });
                break;
            case 'remove':
                if (passengers?.length <= passengerSegmentLimit.min) {
                    toast(`At least ${passengerSegmentLimit.min} passenger must be present.`);
                    return;
                }
                toast.error(`Passenger has been removed.`, { position: toastPosition });
                passengers.splice(index, 1);
                data.itinerary.segments.forEach((flight: ITripFlightSegment) => {
                    flight?.passengerExtra?.splice(index, 1);
                });
                break;
        }
        setTrip(data);
    }
    const handleUpdatePassenger = (passenger: ITripPassenger, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const passengers: ITripPassenger[] = data.itinerary.passengers;
        if (passengers?.at(index)) {
            passengers[index] = passenger;
            setTrip(data);
        }
    }

    const handleFlightSegment = (type: SegmentActionType, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const flights: ITripFlightSegment[] = data.itinerary.segments;
        switch (type) {
            case 'add':
                if (flights?.length >= flightSegmentLimit.max) {
                    toast.error(`The maximum flight limit of ${flightSegmentLimit.max} has been reached.`, { position: toastPosition });
                    return;
                }
                toast.success(`Flight segment has been added.`, { position: toastPosition });
                const flight: ITripFlightSegment = setFlightId(deepCopy(VendorConstants.DEFAULT_TRIP_FLIGHT_SEGMENT));
                for (let i = 1; i < data.itinerary.passengers.length; i++) {
                    flight?.passengerExtra?.push(deepCopy(VendorConstants.DEFAULT_TRIP_PASSENGER_EXTRA));
                }
                flights.splice(index + 1, 0, flight);
                break;
            case 'remove':
                if (flights?.length <= flightSegmentLimit.min) {
                    toast.error(`At least ${flightSegmentLimit.min} flight must be present.`, { position: toastPosition });
                    return;
                }
                toast.error(`Flight has been removed.`, { position: toastPosition });
                flights.splice(index, 1);
                break;
        }
        setTrip(data);
    }
    const handleUpdateFlight = (flight: ITripFlightSegment, index: number) => {
        const data: ITripRequest = JSON.parse(JSON.stringify(trip));
        const flights: ITripFlightSegment[] = data.itinerary.segments;
        if (flights?.at(index)) {
            flights[index] = flight;
            setTrip(data);
        }
    }

    const handleProductFeatureSelection = (data: IProductFeatureSelection[]) => {
        const selectedProductFeatures: ITripProductFeatureSubscription[] = data
            .filter(product => product.selected)
            .map(product => ({
                product: product.prodCode,
                features: product.features
                    .filter(feature => feature.selected)
                    .map(feature => feature.featureCode)
            }));
        setSelectedPdtFeatures(selectedProductFeatures);
        // setupDisplayAndMandatoryFields(selectedProductFeatures);
    }
    const fetchSelectedPdtFeatures = (): ITripProductFeatureSubscription[] | null => {
        const hasSelectedFeatures = selectedPdtFeatures.some(item => item.features.length > 0);
        return (hasSelectedFeatures) ? selectedPdtFeatures : null;
    }
    const setupDisplayAndMandatoryFields = () => {

        // let has_FW_N_only = false;
        let has_FW_display = false;
        let has_FW_N = false;
        let has_FW_RB = false;
        let has_FW_FDA = false;

        const productIds: string[] = [];
        selectedPdtFeatures.forEach(pf => {
            pf.features.forEach(f => productIds.push(`${pf.product}_${f}`));
        })
        // if (productIds.length === 1 && productIds[0] === 'FW_N') has_FW_N_only = true;
        if (productIds.length > 0 && productIds.every(pid => pid === 'FW_N' || pid === 'FW_FDA')) has_FW_display = true;
        for (const pid of productIds) {
            if (pid === 'FW_RB') {
                has_FW_RB = true;
            }
            if (pid === 'FW_FDA') {
                has_FW_FDA = true;
            }
            if (pid === 'FW_N') {
                has_FW_N = true;
            }
        }
        if (has_FW_display) {
            setDisplayFields((prev: ITripDisplayFields) => ({ ...prev, FW_display: true }));
            const data: ITripRequest = JSON.parse(JSON.stringify(trip));
            data.itinerary.passengers = setupContactPassenger(data.itinerary.passengers);
            setTrip(data);
        } else {
            setDisplayFields((prev: ITripDisplayFields) => ({ ...prev, FW_display: false }));
        }
        if (has_FW_N) {
            setMandatoryFields((prev: ITripMandatoryFields) => ({ ...prev, channelType: true }));
        } else {
            setMandatoryFields((prev: ITripMandatoryFields) => ({ ...prev, channelType: false }));
        }
        if (has_FW_RB || has_FW_FDA) {
            setMandatoryFields((prev: ITripMandatoryFields) => ({ ...prev, phone: true }));
        } else {
            setMandatoryFields((prev: ITripMandatoryFields) => ({ ...prev, phone: false }));
        }
    }
    // const isTripTypeA = (selectedProductFeatures: ISelectedProductFeatures[]): boolean => {
    //     const productIds: string[] = [];
    //     selectedProductFeatures.forEach(pf => {
    //         pf.features.forEach(f => productIds.push(`${pf.product}_${f}`));
    //     })
    //     return (productIds.length === 1 && productIds[0] === 'FW_N');
    // }
    const setupContactPassenger = (passengers: ITripPassenger[]): ITripPassenger[] => {
        setPassengerCount(passengers.length.toString());
        return [deepCopy(passengers[0])];
    }
    // const isTripFlightWatcherOnly = () => (tripType === 'A');

    const findSelectedCount = (channelData: IChannelData[]): number => {
        if (!channelData || !channelData.length) return 0;
        return channelData.filter((ch: IChannelData) => ch.selected).length;
    }

    const resetChannelStatus = () => {
        const tempChannels = channels.map(channel => ({ ...channel, selected: false }));
        setChannels(tempChannels)
    }

    const filterSubcribedChannels = (channels: IChannelData[], communicationPreferences: ICommunicationPreferences[]) => {
        const validChannelNames = communicationPreferences?.map((preference) => ({
            name: preference.channel?.name,
            isDefault: preference.channel?.meta?.isDefault ?? false
        }));

        let updatedChannels: IChannelData[] = channels?.map((channel) => {
            const matchingPreference = validChannelNames?.find(
                (validChannel) => validChannel.name === channel.value
            );
            return {
                ...channel,
                selected: matchingPreference?.isDefault ?? false
            };
        }).filter((channel) => validChannelNames.some(valid => valid.name === channel.value));

        setChannels(updatedChannels);
    };

    const handleChannelChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value, checked } = e.target;
        const tempChannels = [...channels]
        tempChannels[index].selected = checked;
        setChannels(tempChannels);
    }
    const getSelectedChanelValues = (): string[] => {
        return channels.filter(channel => channel.selected).map(channel => channel.value);
    };

    const renderBookingDetails = () => {
        return (<div className='row'>
            {!!trip && <>
                <div className='col-md-4'>
                    <div className='cust-form-group'>
                        <label htmlFor={`trip-bookingno`}>
                            Booking {!displayFields.isEmbedded ? 'number' : 'id'}<b className='text-error'>*</b>
                            {displayFields.isEmbedded && <span
                                className='booking-id-info'
                                onMouseEnter={handleInfoPopoverOpen}
                                onMouseLeave={handleInfoPopoverClose}
                            >
                                <InfoOutlinedIcon />
                            </span>}
                        </label>
                        <input
                            name='bookingno'
                            type='text'
                            id={`trip-bookingno`}
                            value={trip.itinerary.bookingno}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateBookingDetails(e)}
                            required
                        />
                        {
                            (dirtyFormFlag && !trip.itinerary.bookingno) &&
                            <div className='text-error'>
                                Please fill out the booking {!displayFields.isEmbedded ? 'number' : 'id'}.
                            </div>
                        }
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='cust-form-group'>
                        <label htmlFor={`trip-language`}>Language</label>
                        <select
                            name='language'
                            id='trip-language'
                            value={trip.itinerary.langCode}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleUpdateTripLanguage(e)}
                        >
                            {VendorConstants.TRIP_LANGUAGE.map((l) => <option key={l.code} value={l.code} disabled={l.disabled}>
                                {l.label}
                            </option>)}
                        </select>
                    </div>
                </div>
            </>}
            {displayFields.FW_display && <>
                <div className='col-md-4'>
                    <div className='cust-form-group'>
                        <label htmlFor={`trip-noofpsngrs`}>No. of passengers<b className='text-error'>*</b></label>
                        <input
                            name='passenger-count'
                            type='number'
                            id={`trip-passenger-count`}
                            min={passengerSegmentLimit.min}
                            max={passengerSegmentLimit.max}
                            value={passengerCount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassengerCountChange(e)}
                            required
                        />
                        {
                            (dirtyFormFlag && !passengerCount) &&
                            <div className='text-error'>Please fill out the number of passengers.</div>
                        }
                        {
                            (dirtyFormFlag && !!passengerCount && (Number(passengerCount) < passengerSegmentLimit.min || Number(passengerCount) > passengerSegmentLimit.max)) &&
                            <div className='text-error'>Number of passengers cannot be less than {passengerSegmentLimit.min} or more than {passengerSegmentLimit.max}.</div>
                        }
                    </div>
                </div>
            </>}
        </div>);
    }

    const renderCommunicationDetails = () => {
        return (<div className='row'>
            <div className='col-md-12'>
                Channel type<b className='text-error'>*</b>
                <span className='text-error'>&nbsp;&nbsp;only applicable for FW only</span>
            </div>

            {channels.map((item: IChannelData, i: number) => {
                return (<div className='col-md-4' key={'channel-type-' + item.value}>
                    <div className='channel-list'>
                        <input
                            type="checkbox"
                            id={'channel-id-' + item.value}
                            name={item.value}
                            value={item.value}
                            checked={item.selected}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChannelChange(e, i)}
                        />
                        <label htmlFor={'channel-id-' + item.value}>{item.label}</label>
                    </div>
                </div>)
            })}

            {(dirtyFormFlag && mandatoryFields.channelType && getSelectedChanelValues()?.length <= 0) &&
                <div className='col-md-12'>
                    <div className='text-error'>Please select at least 1 channel.</div>
                </div>}

        </div>);
    }

    const renderCommercialDetails = () => {
        return (<div className='row'>
            {!!trip && <>
                <div className='col-md-4'>
                    <div className='cust-form-group'>
                        <label htmlFor={`trip-pricing-currency-code`}>Currency Code</label>
                        <input
                            name='pricing-currency-code'
                            type='text'
                            id={`trip-pricing-currency-code`}
                            value={trip.itinerary.pricing.currencyCode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdatePricingDetails(e, 'currency-code')}
                        />
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='cust-form-group'>
                        <label htmlFor={`trip-pricing-price`}>Price</label>
                        <input
                            name='pricing-price'
                            type='number'
                            id={`trip-pricing-price`}
                            value={trip.itinerary.pricing.price}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdatePricingDetails(e, 'price')}
                        />
                    </div>
                </div>
            </>}
        </div>);
    }

    return (
        // key = { keyData }
        < div className='trip-create-form-container' >

            {/* <div>
                {JSON.stringify(selectedPdtFeatures)}
            </div>
            <div>
                {JSON.stringify(productIds)}
            </div>
            <div>
                {JSON.stringify(displayFields)}
            </div>
            <div>
                {JSON.stringify(mandatoryFields)}
            </div> */}

            < Popover
                id='mouseOverPopover'
                sx={{ pointerEvents: 'none' }
                }
                open={!!infoPopoverAnchorEl}
                anchorEl={infoPopoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleInfoPopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1, maxWidth: '175px' }}>
                    Please provide unique ID, which can be your flight booking number, your card number with which you access this benefit or your membership number.
                </Typography>
            </Popover >

            <div className='form-wrapper'>

                <form onSubmit={(e: FormEvent) => { e.preventDefault() }}>
                    <div className='row'>

                        {childrenVendors?.length > 0 && <div className='col-md-12'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>{vendorProfile?.org_name} domain is auto selected</div>
                                <div className="row">
                                    <div className='col-md-4'>
                                        <div className='cust-form-group'>
                                            <label htmlFor={`child-vendor`}>Other Domains</label>
                                            <SubVendorSelector
                                                onSelectionChange={(chVendor: IChildVendor | null) => setSelectedChildVendor(chVendor)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {/* style={{ display: !displayFields.isEmbedded ? 'block' : 'none' }} */}
                        {vendorProfile?.uifeatureVisibility?.productFeatures &&
                            <div className='col-md-12 padding-top'>
                                <div className='form-wrapper'>
                                    <div className='cust-form-label'>Select Product and Service</div>
                                    <div className="row">
                                        <div className="col-md-4 products-features-container">
                                            <div>Product and Service Options<b className='text-error'>*</b></div>
                                            <ProductFeatureSelector
                                                displayFeature={true}
                                                resetFlag={resetFlag}
                                                productIds={productIds}
                                                onSelectProductFeature={(data: IProductFeatureSelection[]) => handleProductFeatureSelection(data)}
                                            />
                                            {
                                                (dirtyFormFlag && !fetchSelectedPdtFeatures()) &&
                                                <div className='text-error'>Please select at least 1 feature.</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {mandatoryFields.channelType && vendorProfile?.uifeatureVisibility.commPreferences && <div className='col-md-12 padding-top'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>Communication Details</div>
                                {renderCommunicationDetails()}
                            </div>
                        </div>}

                        <div className='col-md-12 padding-top'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>Booking Details {displayFields.isEmbedded && '/ Flight Details'}</div>
                                {renderBookingDetails()}
                            </div>
                        </div>

                        {!displayFields.isEmbedded && <div className='col-md-12 padding-top'>
                            <div className='form-wrapper'>
                                <div className='cust-form-label'>Commercial Details</div>
                                {renderCommercialDetails()}
                            </div>
                        </div>}

                        <div className='col-md-12 padding-top'>
                            <div className='passengers-container form-wrapper'>
                                <div className='cust-form-label'>
                                    {displayFields.FW_display ? 'Contact' : 'Passenger'} Details
                                </div>
                                <div className='container-fluid flight-segment-container'>
                                    {
                                        (!!trip && !!trip.itinerary?.passengers) &&
                                        trip.itinerary.passengers.map((passenger: ITripPassenger, i: number) => (
                                            <React.Fragment key={passenger.id}>
                                                <PassengerData
                                                    index={i}
                                                    mandatoryFields={mandatoryFields}
                                                    displayFields={displayFields}
                                                    singular={displayFields.FW_display}
                                                    passenger={passenger}
                                                    dirtyFormFlag={dirtyFormFlag}
                                                    resetFlag={resetFlag}
                                                    onPassengerSegmentChange={(type: SegmentActionType) => { handlePassengerSegment(type, { index: i, count: 1 }) }}
                                                    onUpdatePassenger={(passenger: ITripPassenger) => { handleUpdatePassenger(passenger, i) }}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='passengers-container form-wrapper'>
                                <div className='cust-form-label'>Flight Segment Details</div>
                                <div className='container-fluid flight-segment-container'>
                                    {
                                        (!!trip && !!trip.itinerary?.segments) &&
                                        trip.itinerary.segments.map((flight: ITripFlightSegment, i: number) => (
                                            <React.Fragment key={flight.id}>
                                                <FlightSegmentData
                                                    index={i}
                                                    mandatoryFields={mandatoryFields}
                                                    displayFields={displayFields}
                                                    flight={flight}
                                                    passengers={trip.itinerary.passengers}
                                                    dirtyFormFlag={dirtyFormFlag}
                                                    resetFlag={resetFlag}
                                                    onFlightSegmentChange={(type: SegmentActionType) => { handleFlightSegment(type, i) }}
                                                    onUpdateFlight={(flight: ITripFlightSegment) => { handleUpdateFlight(flight, i) }}
                                                />
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12 padding-top'>
                            <div className='btn-grp-wrapper'>
                                <button type='button' className='button-primary' onClick={resetData}>
                                    Reset
                                </button>
                                <button type='submit' onClick={createTrip} className='button-primary'>
                                    Create
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            {/* {
                !!tripResponse &&
                <div className='padding-top'>
                    <TripCreationResponse
                        tripResponse={tripResponse}
                    />
                </div>
            } */}
            {
                !!tripResponse && tripResponse.successful_segments[tripResponse.successful_segments.length - 1] !== null &&
                <div className='padding-top'>
                    <TripCreationResponse2
                        tripResponse={tripResponse}
                        bookingno={trip?.itinerary.bookingno}
                    />

                </div>
            }
        </div >
    );
};

export default TripCreateForm;