import React, { useEffect, useState } from 'react'
import TripSearchFormA from '../TripSearchFormA/TripSearchFormA'
import NotificationSearchForm from '../NotificationSearchForm/NotificationSearchForm'
import { AppConstants } from '_constants/App.constants';
import { Switch } from '@mui/material';
import { IChildVendor, ITripSearchMandatoryFields, SearchType } from '_models/vendor.interface';
import { deepCopy } from '_services/utils.service';
import { VendorConstants } from '_constants/Vendor.constants';
import './SearchFormA.scss';
import { ISearchField } from '_models/common.interface';

const SearchFormA = (props: { advanceSearchCategories?: any, onSearch: any }) => {
    const [isAdvanceSearchOpen, setIsAdvanceSearchOpen] = useState<boolean>(false);
    const [isNotificationSearchOn, setNotificationSearchOn] = useState<boolean>(false);
    const [formInputs, setFormInputs] = useState<any>({});
    const _onAdvanceSearchOpen = () => {
        setIsAdvanceSearchOpen(!isAdvanceSearchOpen);
    }
    const _onInputFieldChange = (e: any) => {
        let searchReq: any = [];
        if (e.target.value != '') {
            searchReq = [{ "field": "bookingno", "operator": "CONTAINS", "value": e.target.value }];
            if (e.keyCode === 13) props.onSearch(searchReq, 'trip');
        }
    }

    const _onNotificationSearchClick = (e: any) => {
        initialiseForm();
        let v = !isNotificationSearchOn;
        setNotificationSearchOn(v);
    }

    const initialiseForm = () => {
        setFormInputs({});
    }

    const formRequestPayload = (inputData: ISearchField[], searchType: SearchType,chVendor?:IChildVendor|null) => {

        if (searchType === 'trip') {
            props.onSearch(inputData, searchType,chVendor);
        } else {
            props.onSearch(inputData, searchType);
        }
    }

    const onUpdateAdvanceSearchOpen = (isOpen: boolean) => {
        setIsAdvanceSearchOpen(isOpen)
    }
    return (
        <>
            {
                !isAdvanceSearchOpen &&
                <div className="searchWrapper">
                    <div className="searchPretext">
                        <img src={AppConstants.IMAGE_PATH + 'search.png'} alt="search" />
                    </div>
                    <input type="text" className={"searchInput " + (props.advanceSearchCategories ? 'filterOnWidth' : 'fullWidth')} placeholder="Search..." onKeyUp={_onInputFieldChange} />
                    {
                        props.advanceSearchCategories &&
                        <div className="advancedBtn" onClick={_onAdvanceSearchOpen}>
                            <img src={AppConstants.IMAGE_PATH + 'filter.png'} alt="filter" />
                        </div>
                    }
                </div>
            }
            <div className={isAdvanceSearchOpen ? "advanceSearchWrapper " : "advanceSearchWrapper hide"}>
                <div className="col-md-12 d-flex justify-content-center mb-3" >
                    <Switch size="small" onChange={_onNotificationSearchClick} />
                    <div className="ms-2">Turn on Notification Search</div>
                </div>
                {!isNotificationSearchOn ?
                    <TripSearchFormA formInputs={formInputs} setFormInputs={setFormInputs} setIsAdvanceSearchOpen={onUpdateAdvanceSearchOpen} formRequestPayload={formRequestPayload} /> : <NotificationSearchForm formInputs={formInputs} setFormInputs={setFormInputs} setIsAdvanceSearchOpen={onUpdateAdvanceSearchOpen} formRequestPayload={formRequestPayload} />
                }
            </div>
        </>


    )
}

export default SearchFormA
