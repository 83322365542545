import React, { useEffect, useState } from 'react';
import {
    Segmented,
} from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import AirlineTypeahead from '_components/Typeahead/AirlineTypeahead';
import { IAirlineSearch } from '_models/common.interface';
import { ITripSearchDateFields, ITripSearchFields, ITripSearchMandatoryFields } from '_models/vendor.interface';

import { Theme, useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight: personName.includes(name)
            ? theme.typography.fontWeightMedium
            : theme.typography.fontWeightRegular,
    };
}

interface IProductFields {
    label: string;
    name: string;
    fieldType: string;
    defaultOperator: string;
}

interface IDropdownOption {
    code: number,
    label: string

}
interface IFormProps {
    formInputs: any;
    fields: IProductFields[];
    segments?: any;
    onFormSubmit: (btn: string) => void;
    onFormChange: (params: {
        fieldObj: ITripSearchFields;
        value: any;
    }) => void;
    primaryButtonTitle?: string;
    extraButtons?: string[];
    onResetSearch: () => void;
    dirtyFormFlag: boolean;
    searchMandatoryFields: ITripSearchMandatoryFields;
    isNotification?: boolean;
    resetMandotoryField: () => void;
}

const FormBuilder2 = (props: IFormProps) => {
    const [customForm, setCustomForm] = useState<IProductFields[]>([]);
    const [dataFormat, setDataFormat] = useState<ITripSearchDateFields>({} as ITripSearchDateFields);
    const [dateFormate, setDateFormate] = useState<ITripSearchDateFields[]>([]);
    const [airlineCode, setAirlineCode] = useState<string>('')
    const [product, setProduct] = React.useState<string[]>([]);
    const theme = useTheme();
    useEffect(() => {
        if (dateFormate && dateFormate.length > 0) {
            dateFormate.forEach(({ obj, value }) => {
                props.onFormChange({ fieldObj: obj, value: value });
            });


        }
    }, [dateFormate])

    useEffect(() => {
        setCustomForm(props.fields);
    }, [props.fields]);

    const convertToTimestamp = (date: Dayjs): number => {
        const epochTime = Math.floor(date.valueOf() / 1000);
        return epochTime
    }

    const _onChange = (obj: ITripSearchFields, e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target
        if (obj.fieldType === 'TEXT') {
            props.onFormChange({ fieldObj: obj, value: value });
            let values: ITripSearchDateFields = { ...dataFormat, [obj.name]: value };
            setDataFormat(values);
        }
        if (obj.fieldType === 'DATE') {
            let timestamp: number | string | null = value === '' ? null : value;
        
            if (!props.isNotification && value) {
                const day = dayjs.utc(value);
                timestamp = convertToTimestamp(name === 'start' ? day.hour(0).minute(0).second(0) : day.hour(23).minute(59).second(59));
            }
        
            setDateFormate((prevState) => {
                const existingIndex = prevState.findIndex((item) => item.obj.name === obj.name);
                if (existingIndex !== -1) {
                    return prevState.map((item, index) =>
                        index === existingIndex
                            ? {
                                ...item,
                                value: name === "start" ? [timestamp, item.value[1]] : [item.value[0], timestamp],
                            }
                            : item
                    );
                }
                return [
                    ...prevState,
                    {
                        obj,
                        value: name === "start" ? [timestamp, null] : [null, timestamp],
                    },
                ];
            });
        }
        

        if (obj.fieldType === 'DROPDOWN') {
            props.onFormChange({ fieldObj: obj, value: value })

        }
    }

    const _onFinish = () => {
        props.onFormSubmit('submit');
    }
    const _onReset = () => {
        props.onResetSearch()
    };
    const _extraBtnClick = (btn: string) => {
        props.onFormSubmit(btn);
    }

    const handleAirline = (obj: any, value: string) => {
        setAirlineCode(value)
        props.onFormChange({ fieldObj: obj, value: value })
    }

    const handleProduct = (obj: any, event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;
        setProduct(
            typeof value === 'string' ? value.split(',') : value,
        );
        props.onFormChange({ fieldObj: obj, value: value })
    };

    return (
        <>
            <div className='row cust-form-group'>
                {customForm && customForm.map((obj: any, index: number) => (
                    <React.Fragment key={index}>
                        {(obj.fieldType === 'TEXT') && (

                            <div className="col-md-4">
                                <label htmlFor={'channel-id-' + obj.name}>{obj.label}</label>{obj.required ? <span className='text-error'>*</span> : ''}
                                <input
                                    type={obj.fieldType}
                                    id={'channel-id-' + obj.name}
                                    name={obj.name}
                                    onChange={(e) => _onChange(obj, e)}
                                />
                                {
                                    obj.required && props.dirtyFormFlag && props.searchMandatoryFields &&
                                        (props.searchMandatoryFields[obj.name] as boolean)
                                        ? <div className='text-error'>{obj.label} is required</div>
                                        : null
                                }
                            </div>

                        )}
                        {
                            (obj.fieldType === 'DATE') && (obj.extra.range) && (
                                <>
                                    <div className='col-md-8'>

                                        <div className='row'>

                                            <div className='col-md-6'>
                                                <label htmlFor={'channel-id-' + obj.name + 'form'}>Form {obj.label}</label>{obj.required ? <span className='text-error'>*</span> : ''}
                                                <input
                                                    type={obj.fieldType}
                                                    id={'channel-id-' + obj.name + 'form'}
                                                    name='start'
                                                    onChange={(e) => _onChange(obj, e)}
                                                />
                                                {
                                                    props.dirtyFormFlag && props.searchMandatoryFields && obj.required &&
                                                        (props.searchMandatoryFields[obj.name] as [boolean, boolean])?.[0] || (props.formInputs[obj.name] && !props.formInputs[obj.name].value[0] && props.formInputs[obj.name].value[1])
                                                        ? <div className='text-error'>From {obj.label} is required</div>
                                                        : null
                                                }

                                            </div>
                                            <div className='col-md-6'>
                                                <label htmlFor={'channel-id-' + obj.name + 'to'}>To {obj.label}</label>{obj.required ? <span className='text-error'>*</span> : ''}
                                                <input
                                                    type={obj.fieldType}
                                                    id={'channel-id-' + obj.name + 'to'}
                                                    name='end'
                                                    onChange={(e) => _onChange(obj, e)}
                                                />
                                                {
                                                    props.dirtyFormFlag && obj.required &&
                                                        (props.searchMandatoryFields[obj.name] as [boolean, boolean])?.[1] || (props.formInputs[obj.name] && props.formInputs[obj.name].value[0] && !props.formInputs[obj.name].value[1])
                                                        ? <div className='text-error'>To {obj.label} is required</div>
                                                        : null
                                                }
                                            </div>
                                        </div>


                                    </div>

                                </>

                            )

                        }
                        {(obj.fieldType === 'AUTOCOMPLETE') &&
                            <div className='col-md-4'>
                                <label>{obj.label}</label>
                                <AirlineTypeahead
                                    typeaheadId={`Flight${index}Airline`}
                                    airlineCode={airlineCode}
                                    onChange={(selectedAirline: IAirlineSearch | null) => handleAirline(obj, selectedAirline?.code || '')}
                                />
                            </div>

                        }
                        {(index === 1 || index === 3 || index === 4) && <div className="col-md-4"></div>}
                        {
                            (obj.fieldType === 'DROPDOWN') &&
                            <div className='col-md-8 product-select'>
                                <label id="demo-multiple-name-label">{obj.label}</label>
                                <Select
                                    sx={{ width: '100%' }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple={obj.extra.multi}
                                    value={product}
                                    onChange={(e) => handleProduct(obj, e)}
                                    MenuProps={MenuProps}
                                >
                                    {obj.extra.values.map((option: IDropdownOption) => (
                                        <MenuItem
                                            key={option.code}
                                            value={option.code}
                                            style={getStyles(option.label, product, theme)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                        }

                    </React.Fragment>



                ))
                }
                <div className='col-md-12 padding-top'>
                    <div className='btn-grp-wrapper' style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        <button type='submit' className='button-primary' onClick={_onFinish}>
                            {props.primaryButtonTitle ? props.primaryButtonTitle : 'Submit'}
                        </button>
                        <button type='button' className='button-primary' onClick={_onReset}>
                            Reset
                        </button>

                        {
                            props.extraButtons && props.extraButtons.map((btn: any, i: number) => (
                                <button key={"button-" + i} onClick={() => _extraBtnClick(btn)} className='button-primary'>{btn}</button>
                            ))
                        }
                    </div>
                </div>
            </div >
        </>
    );
}

export default FormBuilder2;
