import React, { useState, useEffect } from 'react';
import { ITripResponse, ITripResData, ITripResSegment, ITripResRowData, ITripSuccessfullResData, ITripSuccessResRowData } from '_models/vendor.interface';
import { DataGrid, GridToolbar, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid';
import { DataGridPremium, useGridApiRef, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium';
import { FaCheckCircle, FaTimesCircle, FaInfoCircle } from 'react-icons/fa';
import { getErrorReason, getAviationCode, getFlightTime, renderStatus, renderFlightTimeHeader, getAirportCode } from '_modules/VendorModule/_components/Widget/Widget';
import { MUIXGridSvc } from '_services/muixGrid.service';

interface ITripCreationResponseProps {
    tripResponse: ITripResponse;
    bookingno?: string;
}

const resColData: GridColDef[] = [
    { field: 'isSuccess', headerName: 'Status', width: 75, renderCell: renderStatus },
    { field: 'bookingno', headerName: 'Booking Number', width: 150,flex:1 },
    { field: 'segId', headerName: 'Segment Id', width: 150,flex:1 },
    { field: 'errors', headerName: 'Error Reason', width: 150, valueGetter: getErrorReason,flex:1 },
    { field: 'from', headerName: 'From IATA Code', width: 100 ,flex:1},
    { field: 'to', headerName: 'To IATA Code', width: 100,flex:1 },
];
const resSuccessfullColData: GridColDef[] = [
    { field: 'isSuccess', headerName: 'Status', width: 75, renderCell: renderStatus },
    { field: 'bookingno', headerName: 'Booking Number', width: 150,flex:1 },
    { field: 'productType', headerName: 'Product Type', width: 150 ,flex:1},
    { field: 'airline', headerName: 'Flight', width: 150 ,flex:1},
    { field: 'departureAirport', headerName: 'From', width: 100 ,valueGetter: getAirportCode,flex:1},
    { field: 'arrivalAirport', headerName: 'To', width: 150,valueGetter: getAirportCode,flex:1},
    { field: 'dateTime', headerName: 'Date Time', width: 150,flex:1 }
];

const TripCreationResponse2: React.FC<ITripCreationResponseProps> = (props: ITripCreationResponseProps) => {
    const { tripResponse } = props;
    const [resRowData, setResRowData] = useState<ITripResRowData[]>([]);
    const [successfullResRowData, setSuccessfullResRowData] = useState<ITripSuccessResRowData[]>([]);
    const muixSvc = MUIXGridSvc.getInstance();

    const initialGridState = {
        rowGrouping: {
            model: ['bookingno'],
        },
    }

    useEffect(() => {
        if (tripResponse) {
            const data: ITripResRowData[] = [];
            const data1:ITripSuccessResRowData[]=[]
            if (tripResponse.invalid_segments?.length > 0) {
                tripResponse.invalid_segments?.forEach((seg: ITripResData) => {
                    seg?.invalidSegments?.forEach((segData: ITripResSegment) => {
                        data.push({
                            id: `${seg.bookingno}-${segData.segId}`,
                            isSuccess: false,
                            bookingno: seg.bookingno,
                            ...segData
                        });
                    });
                });
            } else if(tripResponse.successful_segments[tripResponse.successful_segments.length-1]!==null && tripResponse.successful_segments.length>0) {
                tripResponse.successful_segments.forEach((seg: ITripSuccessfullResData) => {
                    if(seg.pType==='FW'){
                    const response = convertSuccessfullResponseFW(seg);
                    data1.push(...response as ITripSuccessResRowData[])
                    }else if(seg.pType==='AC'){
                        const response = convertSuccessfullResponseAC(seg);
                        data1.push(...response as ITripSuccessResRowData[])
                    }

                })
            }
            console.log(data1);
            setSuccessfullResRowData(data1);
            setResRowData(data);
            setTimeout(() => muixSvc.hideLicenseDiv(), 10);
        }
    }, [tripResponse])
    const convertSuccessfullResponseAC = (seg: any) => {
        const uniqueEntries = seg.data.reduce((acc: { has: (arg0: string) => any; set: (arg0: string, arg1: { id: string;isSuccess: boolean; productType: string; bookingno: any; airline: any; departureAirport: any; arrivalAirport: any; dateTime: string; }) => void; }, item: { airline: { iata: any; }; dept: { date: any; time: any; }; from: { icao: any; }; to: { icao: any; }; bookingNo: any; flightnum: any; }) => {
          const airline = item.airline.iata;
          const deptDate = item.dept.date;
          const deptTime = item.dept.time;
          const departureAirport = item.from.icao;
          const arrivalAirport = item.to.icao;
          const dateTime = `${deptDate}T${deptTime}`;
          const id = `${item.bookingNo}-${item.flightnum}-${airline}`;
      
          if (!acc.has(id)) {
            acc.set(id, {
              id: id,
              isSuccess: true,
              productType: seg.pType,
              bookingno: item.bookingNo,
              airline: airline,
              departureAirport: departureAirport,
              arrivalAirport: arrivalAirport,
              dateTime: dateTime
            });
          }
      
          return acc;
        }, new Map());
      
        return Array.from(uniqueEntries.values());
      };

    const convertSuccessfullResponseFW = (segData: ITripSuccessfullResData) => {
        const dataArray = segData.data;
        const resultArray = dataArray.map((dataString) => {
            const splitData = dataString.split('-');
            return {
                isSuccess: true,
                id: `${props.bookingno}-${splitData[0]}`,
                productType:segData.pType,
                bookingno:props.bookingno,
                airline: `${splitData[0]}${splitData[1]}`,
                departureAirport: splitData[2],
                arrivalAirport: splitData[3],
                dateTime: `${splitData[4]}-${splitData[5]}-${splitData[6]}`
            };
        });

        return resultArray;
    };

    return (
        <div className='trip-response-container'>
            {
                !!resRowData.length ?
                    <>
                        <p>
                            <span className='text-danger'><FaTimesCircle /></span> Failed to created trip for {resRowData.length} segment
                        </p>
                        <div className='data-grid data-grid-large'>
                            <DataGridPremium
                                className=''
                                rows={resRowData}
                                columns={resColData}
                                density='compact'
                                slots={{ toolbar: GridToolbar }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                initialState={initialGridState}
                                defaultGroupingExpansionDepth={-1}
                            />
                        </div>
                    </>
                    :
                    <>
                      {
                      successfullResRowData.length>0?<>
                      <p>
                            <span className='text-success'><FaCheckCircle /></span> Trip creation successfull
                        </p>
                        <div>
                            <DataGridPremium
                                className=''
                                rows={successfullResRowData}
                                columns={resSuccessfullColData}
                                density='compact'
                                slots={{ toolbar: GridToolbar }}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                initialState={initialGridState}
                                defaultGroupingExpansionDepth={-1}
                            />
                        </div>
                        </>:null
                        }
                    </>


            }
        </div>
    );
};

export default TripCreationResponse2;