import { GridColumnHeaderParams, GridRenderCellParams, GridValueGetterParams, GridValidRowModel } from "@mui/x-data-grid";
import { ISegment, IDeptArr, IAviationCode, IPassenger, ISearchDataProductFeatures, IProductFeatures } from "_models/vendor.interface";
import { Tooltip } from "react-bootstrap";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";

const getErrorReason = (params: GridValueGetterParams<string[]>) => {
    if (!params.value || !params.value.length) return ''
    return params.value.join('; ');
}
const getAviationCode = (params: GridValueGetterParams<IAviationCode>) => {
    return params.value.iata;
}
// const getFlightTime = (params: GridValueGetterParams<IDeptArr> | GridValueGetterParams<IArrival>) => {
const getFlightTime = (params: GridValueGetterParams<IDeptArr>) => {
    if (!params.value) return '';
    return `${params.value.date} ${params.value.time} (${params.value.tz})`;
}
const renderStatus = (params: GridRenderCellParams<any, boolean>) => {
    if (params.value === true) return (<span className='text-success text-l'><FaCheckCircle /></span>);
    if (params.value === false) return (<span className='text-danger text-l'><FaTimesCircle /></span>);
    return '';
}
const renderFlightTimeHeader = (params: GridColumnHeaderParams, type: 'dept' | 'arrival') => (
    <>
        {type === 'dept' ? 'Departure' : 'Arrival'}&nbsp;
        <Tooltip title={`Local time of the ${type === 'dept' ? 'departure' : 'arrival'} airport in 24 hour clock format`}>
            <span><FaInfoCircle className='text-primary' /></span>
        </Tooltip>
    </>
)

const getNoData = (params: GridValueGetterParams<any>) => {
    if (!params.value) return '';
    return params.value
}
const getTripActiveStatus = (params: any): boolean => {
    const productWithFeatures: ISearchDataProductFeatures[] = params.row.productWithFeatures;
    if (!productWithFeatures) return false;
    if (!productWithFeatures.length) return false;
    return true;
}
const getProductFeatureTypes = (params: GridValueGetterParams<string[]>, productFeatures: IProductFeatures[]) => {
    if (!params.value || !params.value.length) return '';
    const productWithFeatures: ISearchDataProductFeatures[] = params.value;
    productWithFeatures.forEach((o: ISearchDataProductFeatures) => {
        o.name = findProductFeature(o.product, o.feature, productFeatures);
    });
    const productWithFeaturesStr: string[] = productWithFeatures.map((o: ISearchDataProductFeatures) => `${o.name}`);
    return productWithFeaturesStr.join('; ');
}
const findProductFeature = (productBitpos: number, featureBitpos: number, productFeatures: IProductFeatures[]): string => {
    for (const product of productFeatures) {
        if (product.bitpos === productBitpos) {
            const feature = product.features.find(f => f.bitpos === featureBitpos);
            if (feature) {
                return `${product.name}-${feature.name}`;
            }
        }
    }
    return `${productBitpos}-${featureBitpos}`;
}
const getTravelersCount = (params: GridValueGetterParams<IPassenger[]>) => {
    if (!params.value) return '';
    return `${params.value.length}`;
}
const getTime = (params: GridValueGetterParams<any>) => {
    if (!params.value) return '';
    let time = params.value;
    if (time <= 10000000000) {
        time = time * 1000;
    }
    const utcDateTime = (new Date(time)).toISOString().replace('T', ' ').slice(0, 16);
    return utcDateTime;
}
const getTime2 = (params: GridValueGetterParams<any>) => {
    if (!params.row.sentTimeEpochSec) return '';
    const utcDateTime = (new Date(params.row.sentTimeEpochSec * 1000)).toISOString().replace('T', ' ').slice(0, 19);
    return utcDateTime;
}
const formatTime = (ts: number | undefined): string => {
    if (!ts) return '-';
    if (ts <= 10000000000) {
        ts = ts * 1000;
    }
    const utcDateTime = (new Date(ts)).toISOString().replace('T', ' ').slice(0, 16);
    return utcDateTime;
}
const getSegmentData = (params: any, type: 'airline' | 'flightnum' | 'from' | 'to' | 'dept' | 'arrival' | 'fwatchActivationStatus' | 'segmentid', typeSecondary?: 'deactivatedAt' | 'active') => {
    const segment: ISegment = params.row.segment;
    if (!segment) return '';
    if (!segment[type]) return '';
    switch (type) {
        case 'airline':
        case 'from':
        case 'to':
            return segment[type]?.iata;
        case 'dept':
        case 'arrival':
            return `${segment[type]?.date} ${segment[type]?.time} (${segment[type]?.tz})`;
        case 'fwatchActivationStatus':
            if (typeSecondary === 'deactivatedAt') {
                if (segment[type]?.at === -1) return '';
                let time = segment[type]?.at;
                if (time <= 10000000000) {
                    time = time * 1000;
                }
                return (new Date(time)).toISOString().replace('T', ' ').slice(0, 16)
            }
            if (typeSecondary === 'active') {
                return segment[type]?.status
            }
            return '';
        case 'flightnum':
        case 'segmentid':
            return segment[type];
        default:
            return '';
    }
}

const getAirportCode=(params: GridValueGetterParams<any>)=>{
    const localStroageData=localStorage.getItem('allAirports');
    const parsedData = localStroageData ? JSON.parse(localStroageData) : null;
    const code = parsedData.airports.find((item: any) => item.icao === params.value)?.code;
    return code;
    
    }

export {
    getErrorReason,
    getAviationCode,
    getFlightTime,
    renderStatus,
    renderFlightTimeHeader,
    getNoData,
    getTripActiveStatus,
    getProductFeatureTypes,
    getTravelersCount,
    getTime,
    getTime2,
    formatTime,
    getSegmentData,
    getAirportCode
}