import { GridPaginationModel } from '@mui/x-data-grid';
import { ApiService } from './api.service';
import { UrlProviderService } from './urlProvider.service';
import { UtilSvc } from './util.service';
import { ICheckinPassenger, ICheckinSegment, IDispositionRequestPayload, IReloadAutomationPayload, IResetCheckins, IRescheduleCheckin } from '_models/checkin.interface';

export class CheckinSvc extends UrlProviderService {
    private static instance: CheckinSvc;
    private utilSvc = UtilSvc.getInstance();

    private constructor() {
        super('bp-doc-matcher');
    }
    public static getInstance(): CheckinSvc {
        if (!this.instance) {
            this.instance = new CheckinSvc();
        }
        return this.instance;
    }

    private apiSvc: ApiService = ApiService.getInstance(process.env.REACT_APP_BASE_URL);

    private URL_PATH = {
        ASSIGNMENT: 'assignment',
        ITINERARIES: 'itineraries',
    };

    findSelectedCheckins(selectedSegment: ICheckinSegment) {
        const selectedCheckins: any = selectedSegment?.passengers?.filter((p: ICheckinPassenger) => p.selected)?.map((p: ICheckinPassenger) => p?.extraAttributes?.checkinId);
        return selectedCheckins;
    }
    findSelectedFakeCheckins(selectedSegment: ICheckinSegment) {
        const selectedCheckins: any = selectedSegment?.passengers?.filter((p: ICheckinPassenger) => p.selected)?.map((p: ICheckinPassenger) => p?.extraAttributes?.fakeCheckinId);
        return selectedCheckins;
    }

    // getCheckinPoolSearchData(): ISearchField[] {
    //     const currentTs: string | number = Math.floor(Date.now() / 1000);
    //     const searchData: ISearchField[] = JSON.parse(JSON.stringify(CheckinConstants.CHECKIN_POOL_DATA));
    //     return searchData.map((d: ISearchField) => (d.field === 'checkins.dept.deptTimeUTCMillis') ? { ...d, value: currentTs } : d);
    // }
    // getAirlineSearchData(airlineCodes: string[], isAirlinePresent: boolean): ISearchField[] {
    //     const searchData: ISearchField[] = [];
    //     airlineCodes.forEach((code: string) => {
    //         const data: ISearchField = {
    //             field: 'checkins.airline.iata',
    //             operator: isAirlinePresent ? 'EQ' : 'NE',
    //             value: code
    //         };
    //         searchData.push(data);
    //     });
    //     return searchData;
    // }
    // getFutureSearchData(hours: number = 24): ISearchField[] {
    //     const currentTs: string | number = Math.floor(Date.now() / 1000);
    //     const startOffset: number = 24 * 60 * 60;
    //     const endOffset: number = startOffset + hours * 60 * 60;
    //     const searchData: ISearchField[] = [
    //         {
    //             field: 'checkins.dept.deptTimeUTCMillis',
    //             operator: 'GTE',
    //             value: currentTs + startOffset
    //         },
    //         {
    //             field: 'checkins.dept.deptTimeUTCMillis',
    //             operator: 'LTE',
    //             value: currentTs + endOffset
    //         }
    //     ];
    //     return searchData;
    // }

    getAllLockedAssignments(page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/locked?page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    unlockAssignments(checkInIds: string[], bookingNum: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/checkin/unlock?bookingNum=${bookingNum}`);
        return this.apiSvc.post(uri, checkInIds, { setAuth: true });
    }
    unlockAssignmentsByBookingNumbers(bookingNumbers: string[]) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/checkin/bulkunlock`);
        return this.apiSvc.post(uri, bookingNumbers, { setAuth: true });
    }

    getCurrentAssignment(count?: number) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/current`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getNextAssignment(count?: number) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/next?c=${count ? count : 5}`);
        return this.apiSvc.get(uri, { setLoader: false, setAuth: true });
    }
    getPNRsWithMultipleCheckins() {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/multi`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    searchCheckins(payload: any, page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/search?page=${page.page + 1}&size=${page.pageSize}&searchStr=${this.utilSvc.safePayload(payload)}&counter=true`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    searchCheckinsByHourSlab(hourSlab: string, page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkin/pending/byhour/${hourSlab}?page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    searchCheckinsByMultiplePassenger(passengerSlab: string, page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/multipassenger/${passengerSlab}?page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getAfterAutomationData(page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/automation/status?page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getAllCheckinsByPNR(pnr: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/pnr/${pnr}/allcheckins`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/pnr/allcheckins?pnr=${pnr}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getStatusOneWithoutBPData(page: GridPaginationModel) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/autolink/failed?page=${page.page + 1}&size=${page.pageSize}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getDisposedData(page: GridPaginationModel, pass: boolean, autoLink: boolean | null, qc: boolean | null, airlines?: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/current/disposed?page=${page.page + 1}&size=${page.pageSize}&pass=${value}&qc=true${airlines ? ('&airline=' + airlines) : ''}`);
        let url = `${this.URL_PATH.ITINERARIES}/checkins/current/disposed?page=${page.page + 1}&size=${page.pageSize}&pass=${pass}`;
        // if (autoLink !== null) url += `&autolink=${autoLink}`;
        if (qc !== null) url += `&qc=${qc}`;
        if (airlines) url += `&airline=${airlines}`;
        const uri = this.generateUrl(url);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    resetCheckinStatus(payload: IResetCheckins) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/reset`);
        return this.apiSvc.put(uri, payload, { setAuth: true });
    }
    fetchCounters() {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkin/counters`);
        return this.apiSvc.get(uri, { setLoader: false, setAuth: true });
    }

    getCandidatePages(pnr: string, matched: boolean, bookingNum: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/candidatepages?matched=${matched}&bookingNum=${bookingNum}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/candidatepages?matched=${matched}&bookingNum=${bookingNum}&pnr=${pnr}`);
        return this.apiSvc.get(uri, { setLoader: false, setAuth: true });
    }

    linkBP(checkinid: string, dmscode: string, bookingNum: string, pnr: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/${checkinid}/page?page=${dmscode}&bookingNum=${bookingNum}&pnr=${pnr}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/page?checkinid=${checkinid}&page=${dmscode}&bookingNum=${bookingNum}&pnr=${pnr}`);
        return this.apiSvc.put(uri, null, { setAuth: true });
    }
    unlinkBP(checkinid: string, bookingNum: string, pnr: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/${checkinid}/page?pnr=${pnr}&bookingNum=${bookingNum}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/page?checkinid=${checkinid}&pnr=${pnr}&bookingNum=${bookingNum}`);
        return this.apiSvc.delete(uri, { setAuth: true });
    }
    lockPnr(pnr: string, checkinid: string, bookingNum: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/checkins/${checkinid}/lock?bookingNum=${bookingNum}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/lock?pnr=${pnr}&bookingNum=${bookingNum}&checkinid=${checkinid}`);
        return this.apiSvc.put(uri, null, { setAuth: true });
    }
    unlockPnr(pnr: string, checkinid: string, bookingNum: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/checkins/${checkinid}/unlock`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/unlock?pnr=${pnr}&checkinid=${checkinid}&bookingNum=${bookingNum}`);
        return this.apiSvc.put(uri, null, { setAuth: true });
    }
    releaseAssignment() {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/checkin/release`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    releaseAutomation(timeInMins: number) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/automation/release?mins=${timeInMins}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    sendBP(pnr: string, checkinid: string, bookingNum: string, allDisposed: boolean) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/checkins/${checkinid}/bp?bookingNum=${bookingNum}&allDisposed=${allDisposed}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/bp?pnr=${pnr}&checkinid=${checkinid}&bookingNum=${bookingNum}&allDisposed=${allDisposed}`);
        return this.apiSvc.post(uri, null, { setAuth: true });
    }
    uploadBP(payload: FormData) {
        const uri = this.generateUrl(`dms`);
        return this.apiSvc.post(uri, payload, { setAuth: true });
    }
    getSearchFields(searchType: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/fields?type=${searchType}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getDisposeData() {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/disposes`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    dispositionRequest(payload: IDispositionRequestPayload, pnr: string, bookingNum: string) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/checkins/dispose?bookingNum=${bookingNum}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/dispose?pnr=${pnr}&bookingNum=${bookingNum}`);
        return this.apiSvc.post(uri, payload, { setAuth: true });
    }
    getPastData(startDate: string, endDate: string, operatorId: string, page: GridPaginationModel) {
        let query = `page=${page.page + 1}&size=${page.pageSize}`;
        if (startDate && endDate) {
            query = `${query}&from=${startDate}&to=${endDate}`;
        }
        if (operatorId) {
            query = `${query}&opid=${operatorId}`;
        }
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/past?${query}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    getToBeLinkedData(bookingNum: string, pnr: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/tobelinked?bookingNum=${bookingNum}&pnr=${pnr}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    searchCheckinsByPNR(pnr: string, simulation: boolean, allDisposed: boolean) {
        // let uriData = `${this.URL_PATH.ITINERARIES}/pnr/${pnr}?simulation=${simulation}`;
        let uriData = `${this.URL_PATH.ITINERARIES}/pnr?pnr=${pnr}&simulation=${simulation}`;
        const uri = this.generateUrl(uriData);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    getPerformanceReportData(startDate: string, endDate: string, operatorId: string, timeZone: string, createdDate: boolean) {
        let query = ``;
        if (startDate && endDate && timeZone) {
            // &tz=${timeZone}
            query = `f=${startDate}&t=${endDate}`;
        }
        if (operatorId) {
            query = `${query}&operatorId=${operatorId}`;
        }
        if (createdDate) {
            query = `${query}&createdDate=${createdDate}`
        }
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/productivity?${query}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getAutomationEfficiencyReportData(from: string, to: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/360?from=${from}&to=${to}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    getTimeSpanReportData(from: string, to: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/throughput?from=${from}&to=${to}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    fetchBillingReportAutoCheckin(from: string, to: string, vid: string, deactivated: boolean) {
        // &deactivated=${deactivated}
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/billing?f=${from}T00:00:00&t=${to}T23:59:59&vid=${vid}`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    reloadAutomation(payload: IReloadAutomationPayload) {
        const uri = this.generateUrl(`${this.URL_PATH.ASSIGNMENT}/automation/reload`);
        return this.apiSvc.post(uri, payload, { setAuth: true });
    }
    getAllLockedStatus() {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/lockstatus`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
    reScheduleCheckinPoolHours(pnr: string, payload: IRescheduleCheckin) {
        // const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/${pnr}/checkins/delay?bookingNum=${bookingNum}&hoursBefore=${hours}`);
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/checkins/delay?pnr=${pnr}`);
        return this.apiSvc.put(uri, payload, { setAuth: true });
    }

    getAllReports() {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/jobs`);
        return this.apiSvc.get(uri, { setAuth: true });
    }

    getTotalCheckinReport(from: string, to: string, vid: string) {
        const uri = this.generateUrl(`${this.URL_PATH.ITINERARIES}/report/checkins/bydaterange?vid=${vid}&from=${from}&to=${to}&tz=UTC`);
        return this.apiSvc.get(uri, { setAuth: true });
    }
}