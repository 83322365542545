import React, { useEffect, useRef, useState } from 'react';
import { IAirlineSearch } from '_models/common.interface';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { RefDataSvc } from '_services/refData.service';
import { debounce } from 'lodash';

interface IAirlineTypeaheadProps {
    typeaheadId: string;
    airlineCode: string;
    onChange: (selectedAirline: IAirlineSearch | null) => void;
}

const AirlineTypeahead: React.FC<IAirlineTypeaheadProps> = ({
    typeaheadId,
    airlineCode,
    onChange,
}: IAirlineTypeaheadProps) => {
    const refDataSvc = RefDataSvc.getInstance();

    const airlineRef = useRef<any>(null);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const [defaultList, setDefaultList] = useState<IAirlineSearch[]>([]);
    const [airlineList, setAirlineList] = useState<IAirlineSearch[]>([]);
    const [typeaheadKey, setTypeaheadKey] = useState(Date.now());

    useEffect(() => {
        if (airlineCode) {
            fillupAirline();
        }
    }, []);

    const fillupAirline = async () => {
        const airlineData: IAirlineSearch | null = await refDataSvc.getAirlineDetails(airlineCode);
        if (airlineData) {
            setDefaultList([airlineData]);
            setTypeaheadKey(Date.now());
        }
    }

    const dbouncedSearchAirlines = debounce((query: string) => {
        handleSearchAirlines(query);
    }, 300);
    const handleSearchAirlines = async (query: string) => {
        try {
            setLoadingFlag(true);
            const res = await refDataSvc.searchAirline(query);
            if (res?.data) {
                setAirlineList(res?.data);
            } else {
                toast.warning('Failed to search airlines. Response status is false.');
                console.warn(res);
            }
        } catch (err: any) {
            toast.error(`Failed to search airlines.`);
            console.error(err);
        } finally {
            setLoadingFlag(false);
        }
    }
    const handleAirlineSelection = (selected: IAirlineSearch[]) => {
        if (selected?.[0]?.code) {
            onChange(selected[0]);
            airlineRef?.current?.blur();
        } else {
            onChange(null);
        }
    }
    const handleBlurAirlineTypeahead = () => {
        if (!airlineCode) {
            airlineRef?.current?.clear();
        }
    }

    return (
        <>
            <AsyncTypeahead
                key={typeaheadKey}
                ref={airlineRef}
                id={`airline${typeaheadId}`}
                className='typeahead'
                isLoading={loadingFlag}
                minLength={2}
                filterBy={() => true}
                useCache={false}
                labelKey={(airline: any) => `${(airline as IAirlineSearch).code} - ${(airline as IAirlineSearch).name}`}
                onSearch={(query: string) => dbouncedSearchAirlines(query)}
                onChange={(selected: any[]) => handleAirlineSelection(selected as IAirlineSearch[])}
                onBlur={() => handleBlurAirlineTypeahead()}
                options={airlineList}
                defaultSelected={defaultList}
                placeholder=''
                renderMenuItemChildren={(airline: any) => (
                    <span>{(airline as IAirlineSearch).code} - {(airline as IAirlineSearch).name}</span>
                )}
            />
        </>
    );
}

export default AirlineTypeahead;