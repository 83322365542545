import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, Navigate, Outlet, useNavigate } from 'react-router-dom';
import './i18n/i18n';
import './App.scss';
import Loading from './_pages/Loading/Loading';
import LayoutMain from './_pages/LayoutMain/LayoutMain';
import { ToastProvider } from '_contexts/ToastContext';
import { AuthSvc } from "./_services/auth.service";
import { AppConstants } from '_constants/App.constants';
import { IStorage } from '_models/storage.interface';
import { StorageProvider } from '_services/storageProvider.service';
import PassportMissingInfo from '_pages/PassportMissingInfo/PassportMissingInfo';
import { displayNotifications } from "_services/logoutNotification.service";
import Loader from '_components/Loader/Loader';
import EmbeddedModuleRouter from 'embedded/EmbeddedModuleRouter';

const MainModuleRouter = lazy(() => import('./_modules/MainModule/MainModuleRouter'));
const VendorModuleRouter = lazy(() => import('./_modules/VendorModule/VendorModuleRouter'));
const LoginModuleRouter = lazy(() => import('./_modules/AuthModule/AuthModuleRouter'));
const AdminModuleRouter = lazy(() => import('./_modules/AdminModule/AdminModuleRouter'));
const SalesModuleRouter = lazy(() => import('./_modules/SalesModule/SalesModuleRouter'));
// const BookingModuleRouter = lazy(() => import('./_modules/BookingModule/BookingModuleRouter'));
// const BookingModuleContentOnlyRouter = lazy(() => import('./_modules/BookingModule/BookingModuleContentOnlyRouter'));
const CheckinOperatorModuleRouter = lazy(() => import('./_modules/CheckinOperatorModule/CheckinOperatorModuleRouter'));
// const FlightDboardModuleRouter = lazy(() => import('./_modules/FlightDboardModule/FlightDboardModuleRouter'));
const FsbModuleRouter = lazy(() => import('./_modules/FsbModule/FsbModuleRouter'));
// const UpcomingTripsModuleRouter = lazy(() => import('./_modules/UpcomingTripsModule/UpcomingTripsModuleRouter'));
const FG48OperatorModuleRouter = lazy(() => import('./_modules/FG48OperatorModule/FG48OperatorModuleRouter'));

const App = () => {
  const authSvc = AuthSvc.getInstance();
  const storageSvc: IStorage = StorageProvider.getStorage();
  const navigate = useNavigate();

  useEffect(() => {
    displayNotifications();
  }, [])

  useEffect(() => {
    let r = window.location.href.replace(`${window.location.origin}/`, '').split('/')[0];
    // console.log(window.location.href);
    const protectedPath = ['login', 'embedded', ...Object.values(AppConstants.ROLES)];
    if (!protectedPath.includes(r)) return;

    if (r === 'embedded') {
      // Token will be handled differently for embedded routes
      return;
    }

    let t: any = storageSvc.get(AppConstants.TOKEN.AUTH);
    let rolePath = null;
    if (t) rolePath = authSvc.extractAndDecodeTokenAndGetRolePath(t);
    if (t && r != rolePath) {
      navigate(`/${rolePath}`);
      // console.log('redirecting to ', rolePath);
    }
  }, [window.location.href])

  return (
    <>
      <Loader />
      <ToastProvider>
        <LayoutMain>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path='/*' Component={MainModuleRouter} />
              <Route path='/sales/*' Component={SalesModuleRouter} />
              <Route element={<PrivateRoutes />}>
                <Route path='/vendor/*' Component={VendorModuleRouter} />
                <Route path='/admin/*' Component={AdminModuleRouter} />
                <Route path='/checkin/*' Component={CheckinOperatorModuleRouter} />
                <Route path='/flight_guard/*' Component={FG48OperatorModuleRouter} />
              </Route>
              <Route path='/login/*' Component={LoginModuleRouter} />
              {/* <Route path='/fsb/*' Component={FsbModuleRouter} /> */}
              {/* <Route path='/fg48-info/*' Component={BookingModuleRouter} /> */}
              {/* <Route path='/fg48/*' Component={BookingModuleContentOnlyRouter} /> */}
              <Route path='/fg48/*' Component={FsbModuleRouter} />
              <Route path='/missing-info/*' Component={PassportMissingInfo} />
              {/* <Route path='/upcoming-flights/*' Component={FlightDboardModuleRouter} /> */}
              {/* <Route path='/upcoming-trips/*' Component={UpcomingTripsModuleRouter} /> */}
              <Route path='/embedded/*' Component={EmbeddedModuleRouter} />
            </Routes>
          </Suspense>
        </LayoutMain>
      </ToastProvider>
    </>
  );
}

export default App;


const PrivateRoutes = () => {
  const authSvc = AuthSvc.getInstance();
  return (
    authSvc.isLoggedIn() ? <Outlet /> : <Navigate to='/login' />
  )
}
