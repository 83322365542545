import { AppConstants } from '_constants/App.constants';
import { VendorConstants } from '_constants/Vendor.constants';
import { IChildVendor, ITripSearchFields, ITripSearchMandatoryFields, SearchType } from '_models/vendor.interface';
import FormBuilder2 from '_pages/FormBuilder/FormBuilder2';
import { LocalStorageSvc } from '_services/localStorage.service';
import { deepCopy } from '_services/utils.service';
import { VendorMgmntSvc } from '_services/vendorMgmnt.service';
import React, { useEffect, useState } from 'react'
import SubVendorSelector from '../SubVendorSelector/SubVendorSelector';
import { useVendorDataContext } from '_contexts/VendorDataContext';
import { useToast } from '_contexts/ToastContext';
import { ISearchField } from '_models/common.interface';

const TripSearchFormA = (props: { formInputs: any, setFormInputs: any, setIsAdvanceSearchOpen: any, formRequestPayload:(reqPayload:ISearchField[],searchType:SearchType,chVendor:IChildVendor|null)=>void }) => {
    const [searchFields, setSearchFields] = useState<any[]>([]);
    const [keyData, setKeyData] = useState<number>(Date.now());
    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const vendorMgmtSvc = VendorMgmntSvc.getInstance();
    const [searchMandatoryFields, setSearchMandatoryFields] = useState<ITripSearchMandatoryFields>(deepCopy(VendorConstants.SEARCH_MANDATORY_FIELDS))
    const localStroage = LocalStorageSvc.getInstance();
    const [selectedChildVendor, setSelectedChildVendor] = useState<IChildVendor | null>(null);
    const { childrenVendors } = useVendorDataContext();
    const toast = useToast();

    useEffect(() => {
        getSearchFields();
    }, []);
    const getSearchFields = () => {
        const key = AppConstants.LOCAL_STORAGE_KEY.TRIP_SEARCH_FIELDS;
        const data: ITripSearchFields[] | null = localStroage.get(key)
        if (data && data?.length > 0) {
            setSearchFields(data);
        } else {
            vendorMgmtSvc.getSearchFields('TRIP_SEARCH').then((res: any) => {
                setSearchFields(res.data.fields);
                localStroage.post(key, res.data.fields)
            });
        }
    }

    const _onFormSubmit = (btn: string) => {
        switch (btn) {
            case 'submit':
                _onTripFormSubmit();
                break;
            case 'Cancel':
                props.setIsAdvanceSearchOpen(false);
                // props.setFormInputsMap({});
                // props.onSearch(reqPayload, 'trip');
                break;
            case 'Hide':
                props.setIsAdvanceSearchOpen(false);
                return;
        }
    }
    const filterNullValues = (data: Record<string, any>) => {
        return Object.fromEntries(
          Object.entries(data).filter(
            ([, value]) => !(Array.isArray(value.value) && value.value.every((v: null) => v === null))
          )
        );
      };

    const _onTripFormSubmit = () => {
        let reqPayload: ISearchField[] = [];
        let isValid = true;
        const formInputs=filterNullValues(props.formInputs)
        if (Object.keys(formInputs).length > 0) {
            Object.keys(formInputs).forEach((key: string) => {
                if (formInputs[key] && formInputs[key].operator && !formInputs[key].value.includes(null)) {
                    reqPayload.push({ 'field': key, 'operator': formInputs[key].operator, 'value': formInputs[key].value });
                } else {
                    isValid = false;
                }
            });
        }
        if (!isValid) {
            toast('Please fill/select all mandatory fields correctly.', 'error');
        } else {
            props.formRequestPayload(reqPayload, 'trip',selectedChildVendor)
        }
    }

    const _onFormChange = (values: { fieldObj: ITripSearchFields, value: (number | null)[] }) => {
        if (values.value.includes(null)) {
            setDirtyFormFlag(true)
        } else {
            setDirtyFormFlag(false)
        }
        if (!values.value || (Array.isArray(values.value) && values.value.length === 0)) {
            let temp = { ...props.formInputs };
            delete temp[values.fieldObj.name];
            props.setFormInputs(temp);
            return;
        } else {
            props.setFormInputs((preav: any) => {
                return {
                    ...preav, [values.fieldObj.name]: {
                        operator: values.fieldObj.extra?.range ? 'BETWEEN' : values.fieldObj.defaultOperator,
                        value: values.value
                    }
                }
            }
            )
        }
    }

    const onResetSearch = () => {
        setKeyData(Date.now())
        props.setFormInputs({})
        // setFormInputsMap({})
        // resetMandotoryField()
        setDirtyFormFlag(false)
        // setupMandotoryFields()
        setSelectedChildVendor(null)
        // props.formRequestPayload(deepCopy(VendorConstants.DEFAULT_TRIP_SEARCH_REQUEST), 'trip',null)
    }

    const resetMandotoryField = () => {
        setSearchMandatoryFields(deepCopy(VendorConstants.SEARCH_MANDATORY_FIELDS))
        props.setFormInputs({})
    }
    return (
        <div>
            <div className='row'>
                <div className='col-md-4'>
                    {childrenVendors?.length > 0 && <div className='cust-form-group' key={keyData}>
                        <label htmlFor={`child-vendor`}>Domain</label>
                        <SubVendorSelector
                            onSelectionChange={(chVendor: IChildVendor | null) => setSelectedChildVendor(chVendor)}
                        />
                    </div>}
                </div>
            </div>
            {searchFields &&
                <FormBuilder2
                    key={keyData}
                    fields={searchFields}
                    formInputs={props.formInputs}
                    primaryButtonTitle="Search"
                    extraButtons={['Cancel', 'Hide']}
                    onFormSubmit={_onFormSubmit}
                    onFormChange={_onFormChange}
                    onResetSearch={onResetSearch}
                    dirtyFormFlag={dirtyFormFlag}
                    searchMandatoryFields={searchMandatoryFields}
                    resetMandotoryField={resetMandotoryField}
                />
            }
        </div>
    )
}

export default TripSearchFormA
