import React, { useEffect, useState } from 'react'
import {
    Segmented,
} from 'antd';
import FormBuilder2 from '_pages/FormBuilder/FormBuilder2';
import { ITripSearchFields, ITripSearchMandatoryFields } from '_models/vendor.interface';
import { deepCopy } from '_services/utils.service';
import { VendorConstants } from '_constants/Vendor.constants';
import { useToast } from '_contexts/ToastContext';
const notificationSearchForm = {
    "fields": [
        {
            "name": "createdAt",
            "label": "Creation Date",
            "fieldType": "DATE",
            "defaultOperator": "EQ",
            "extra": {
                "range": true
            },
            "required": true
        },
        {
            "name": "bookingno",
            "label": "Booking Number",
            "fieldType": "TEXT",
            "defaultOperator": "CONTAINS",
            "extra": null,
            "required": true
        },
        {
            "name": "airline",
            "label": "Airline (autocomplete)",
            "fieldType": "AUTOCOMPLETE",
            "defaultOperator": "IN",
            "extra": {
                "sourcetype": "REMOTE",
                "datatype": "object",
                "valuefield": "name",
                "labelfield": "name",
                "url": ""
            }
        },
        {
            "name": "flightno",
            "label": "Flight Number",
            "fieldType": "TEXT",
            "defaultOperator": "EQ",
            "extra": null
        },
    ]
}
const notificationSearchSegments = [
    { 'name': 'By Creation Date', 'segment': ['createdAt'], isSelected: true },
    { 'name': 'By Booking Number', 'segment': ['bookingno', 'airline', 'flightno'], isSelected: false }
]
const NotificationSearchForm = (props: { setFormInputs: any, setIsAdvanceSearchOpen: any, formInputs: any, formRequestPayload: any }) => {
    const toast = useToast();
    const [segmentName, setSegmentName] = useState<string | null>(null);
    const [searchFields, setSearchFields] = useState<any[]>([]);
    const [keyData, setKeyData] = useState<number>(Date.now());
    const [dirtyFormFlag, setDirtyFormFlag] = useState<boolean>(false);
    const [searchMandatoryFields, setSearchMandatoryFields] = useState<ITripSearchMandatoryFields>(deepCopy(VendorConstants.SEARCH_MANDATORY_FIELDS))

    useEffect(() => {
        setupMandotoryFields()
    }, [props.formInputs])

    useEffect(() => {
        if (notificationSearchForm.fields?.length > 0) {
            if (notificationSearchSegments?.length > 0) {
                _selectSegment();
            } else {
                setSearchFields(notificationSearchForm.fields);
                setSegmentName(null);
            }
        }
    }, [notificationSearchForm, notificationSearchSegments]);

    const _selectSegment = (segmentName?: string) => {
        if (!segmentName)
            segmentName = notificationSearchSegments[0].name;
        let fields = _getFieldsBySegment(segmentName as string);
        setSegmentName(segmentName);
        setSearchFields(fields);
    }

    const _getFieldsBySegment = (segname: string) => {
        let tempFields: any[] = [];
        let segmentData = notificationSearchSegments?.find((el: any) => el.name === segname);

        if (segmentData && segmentData.segment) {
            segmentData.segment.forEach((fname: any) => {
                let field = notificationSearchForm.fields.find((el: any) => el.name === fname);
                if (field) tempFields.push(field);
            });
        }

        return tempFields;
    };

    const _onFormSubmit = (btn: string) => {
        let reqPayload: any = [];
        switch (btn) {
            case 'submit':
                _onNotificationFormSubmit(segmentName);
                break;
            case 'Cancel':
                props.setIsAdvanceSearchOpen(false);
                break;
            case 'Hide':
                props.setIsAdvanceSearchOpen(false);
                return;
        }
    }
    const setupMandotoryFields = () => {
        for (const key in props.formInputs) {
            if (props.formInputs.hasOwnProperty(key)) {
                const value = props.formInputs[key];

                if (Array.isArray(value.value)) {

                    setSearchMandatoryFields((prev) => {
                        return { ...prev, [key]: [!value.value?.[0], !value.value?.[1]] }
                    })


                } else {
                    setSearchMandatoryFields((prev) => {
                        return { ...prev, [key]: !value.value }
                    })
                }
            }
        }



    }


    const _onNotificationFormSubmit = (segment: any) => {
        setDirtyFormFlag(true)
        setupMandotoryFields()
        let reqPayload: any = [];
        if (segment) {
            notificationSearchSegments.find((el: any) => el.name === segment)?.segment.forEach((key: any) => {
                if (!Array.isArray(searchMandatoryFields[key])
                    ? searchMandatoryFields[key] === true
                    : (searchMandatoryFields[key] as boolean[]).some(item => item === true)
                ) {
                    toast('Please fill/select all mandatory fields correctly.', 'error');
                } else {
                    reqPayload.push({ 'field': key, 'value': key === 'airline' ? props.formInputs[key]?.value : props.formInputs[key]?.value });
                }
            });
        }
        props.formRequestPayload(reqPayload, 'notification')

    }

    const resetMandotoryField = () => {
        setSearchMandatoryFields(deepCopy(VendorConstants.SEARCH_MANDATORY_FIELDS))
        props.setFormInputs({})
    }
    const _onFormChange = (values: { fieldObj: any, value: any }) => {
        if (values.value.includes(null)) {
            setDirtyFormFlag(true)
        } else {
            setDirtyFormFlag(false)
        }
        if (!values.value || (values.value.constructor === Array && values.value.length === 0)) {
            let temp = { ...props.formInputs };
            delete temp[values.fieldObj.name];
            props.setFormInputs(temp);
            return;
        } else {
            props.setFormInputs({
                ...props.formInputs,
                [values.fieldObj.name]: {
                    operator: values.fieldObj.extra?.range ? 'BETWEEN' : values.fieldObj.defaultOperator,
                    value: values.value
                }
            });
        }
    }
    const onResetSearch = () => {
        setKeyData(Date.now())
        props.setFormInputs({})
        setDirtyFormFlag(false)
        setSearchMandatoryFields(deepCopy(VendorConstants.SEARCH_MANDATORY_FIELDS))
    }

    return (
        <>
            {
                notificationSearchSegments && notificationSearchSegments.length > 0 &&
                <div className='col-md-12 d-flex justify-content-center mb-4'>
                    <Segmented<string>
                        options={notificationSearchSegments.map((s: any) => s.name)}
                        onChange={(value) => _selectSegment(value)}
                    />
                </div>
            }
            {searchFields &&
                <FormBuilder2
                    key={keyData}
                    fields={searchFields}
                    isNotification={true}
                    formInputs={props.formInputs}
                    primaryButtonTitle="Search"
                    extraButtons={['Cancel', 'Hide']}
                    onFormSubmit={_onFormSubmit}
                    onFormChange={_onFormChange}
                    onResetSearch={onResetSearch}
                    dirtyFormFlag={dirtyFormFlag}
                    searchMandatoryFields={searchMandatoryFields}
                    resetMandotoryField={resetMandotoryField}
                />
            }
        </>
    )
}

export default NotificationSearchForm
